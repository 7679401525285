'use strict';

// core version + modules:
import Swiper, { Navigation, Scrollbar, A11y } from "swiper";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';

const SliderTimeline = {
    els: null,
    init: function () {
        SliderTimeline.els = document.querySelectorAll('.f-slider.timeline--');
        if (SliderTimeline.els && SliderTimeline.els.length > 0) {
            SliderTimeline.els.forEach(el => {
                SliderTimeline.create(el);
                window.addEventListener('resize', () => SliderTimeline.handleArrows(el))
                SliderTimeline.handleArrows(el)
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');
        const nextEl = el.querySelector(".f-slider__navigation-next")
        const prevEl = el.querySelector(".f-slider__navigation-prev")

        let swiper = null;

        swiper = new Swiper(slider, {
            modules: [Navigation, A11y],
            a11y: true,
            slidesPerView: "auto",
            speed: 600,
            autoHeight: false,
            observer: true,
            //wrapperEl: sliderWrapper,
            breakpoints: {
                300: {
                    spaceBetween: 15,
                },
                767: {
                    spaceBetween: 74,
                }
            },
            navigation: {
                prevEl: prevEl,
                nextEl: nextEl,
            }
        });
    },
    handleArrows: function(slider) {
        const swiperWidth = parseFloat(getComputedStyle(slider.querySelector('.swiper')).width); 
        const containerWidth = slider.querySelector('.swiper-wrapper').scrollWidth;
        const nav = slider.querySelector('.f-slider__navigation')

        if(containerWidth > swiperWidth){
            nav.classList.add("visible--")
        }
        else {
            nav.classList.remove("visible--")
        }
    }
};

export default SliderTimeline;