'use strict';

// core version + modules:
import Swiper, { Navigation, Scrollbar, A11y } from "swiper";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';


const SliderSingle = {
    els: null,
    init: function () {
        SliderSingle.els = document.querySelectorAll('.f-slider.single--');
        if (SliderSingle.els && SliderSingle.els.length > 0) {
            SliderSingle.els.forEach(el => {
                SliderSingle.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');
        let swiper = null;

        const nextEl = el.querySelector(".f-slider__navigation-next")
        const prevEl = el.querySelector(".f-slider__navigation-prev")

        if (slider.dataset.navigation === "arrows") {
            swiper = new Swiper(slider, {
                modules: [Navigation, A11y],
                a11y: true,
                spaceBetween: 30,
                slidesPerView: 1,
                loop: false,
                speed: 600,
                autoHeight: true,
                navigation: {
                    prevEl: prevEl,
                    nextEl: nextEl,
                }
            });
        }
    },
};

export default SliderSingle;