'use strict';

// core version + modules:
import Swiper, { Navigation, A11y } from "swiper";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';

const SliderMulti = {
    els: null,
    init: function () {
        SliderMulti.els = document.querySelectorAll('.f-slider.multi--');
        if (SliderMulti.els && SliderMulti.els.length > 0) {
            SliderMulti.els.forEach(el => {
                SliderMulti.create(el);
            });
        }
    },
    checkMargin: function (slider) {
        let images = slider.querySelectorAll(".c-img img");
        let maxHeight = 0;
        images.forEach(img => {
            if (img.clientHeight > maxHeight) {
                maxHeight = img.clientHeight;
            }
        });
        images.forEach(img => {
            img.style.marginTop = Math.floor(0.5 * (maxHeight - img.clientHeight)) + "px";
        });
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');
        const nextEl = el.querySelector(".f-slider__navigation-next")
        const prevEl = el.querySelector(".f-slider__navigation-prev")

        let swiper = null;

        swiper = new Swiper(slider, {
            modules: [Navigation, A11y],
            a11y: true,
            spaceBetween: 10,
            slidesPerView: 3,
            loop: false,
            speed: 600,
            autoHeight: false,
            breakpoints: {
                300: {
                    slidesPerView: 1,
                    autoHeight: true,
                },
                767: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3
                }
            },
            navigation: {
                prevEl: prevEl,
                nextEl: nextEl,
            },
            on: {
                init: function () {
                    // Vérification du nombre total de slides
                    if (this.slides.length <= this.params.slidesPerView) {
                        nextEl.style.display = 'none';
                        prevEl.style.display = 'none';
                    }
                }
            }
        });

        if(el.classList.contains("diapo--")){
            document.addEventListener("resize", () => SliderMulti.checkMargin(slider));
            SliderMulti.checkMargin(slider);
        }
    },
};

export default SliderMulti;