'use strict';

const Header = {
    el: null,
    app: null,
    toggleMenuButton: null,
    themeSwitchButton: null,
    menuItems: null,
    allMenuItems: null,
    menu: null,
    init: function () {
        this.el = document.querySelector('.header');
        this.app = document.querySelector('.app');
        if (this.el) {
            this.toggleMenuButton = this.el.querySelector('.header__toggle');
            this.themeSwitchButton = this.el.querySelector('.js-switch-mode');
            this.menuItems = this.el.querySelectorAll('.menu-item-has-children > a');
            this.allMenuItems = this.el.querySelectorAll('.header__nav > a');
            this.menu = this.el.querySelector('.header__nav');
            this.setMenuItemsTabIndex(-1);
           
            this.el.querySelectorAll('.sub-menu').forEach(submenu => {
                if (submenu.classList.contains('expanded')) {
                    submenu.querySelectorAll('a').forEach(link => {
                        link.tabIndex = 0;
                    })
                } else {
                    submenu.querySelectorAll('a').forEach(link => {
                     
                        link.tabIndex = -1;
                    })
                }
            })
            if (this.toggleMenuButton) {
                this.toggleMenuButton.addEventListener('click', () => {
                    this.el.classList.toggle('navOpened');
                    let expanded = this.el.classList.contains('navOpened');
                    if (window.innerWidth < 767) {
                        document.body.style.overflowY = expanded ? 'hidden' : 'visible';
                    } else {
                        document.body.style.overflowY = 'visible';
                    }
                    this.toggleMenuButton.setAttribute('aria-expanded', expanded);
                    this.setMenuItemsTabIndex(expanded ? 0 : -1);

                    if (expanded) {
                        document.addEventListener('focusin', this.handleFocusOut.bind(this));
                    } else {
                        document.removeEventListener('focusin', this.handleFocusOut.bind(this));
                        
                    }
                });
            }
            if (this.themeSwitchButton) {
                this.themeSwitchButton.addEventListener('click', this.toggleTheme.bind(this));
            }
            this.menuItems.forEach(item => {
                item.addEventListener('click', (event) => {
                    event.preventDefault();
                    let parentLi = item.parentElement;
                    let subMenu = parentLi.querySelector('.sub-menu');
                    let isExpanded = subMenu.classList.contains('expanded');

                    if (!isExpanded) {
                        subMenu.classList.add('expanded');
                        item.setAttribute('aria-expanded', 'true');

                        subMenu.querySelectorAll('a').forEach(link => {
                            link.tabIndex = 0;
                        });

                        parentLi.parentElement.querySelectorAll('.sub-menu').forEach(menu => {
                            if (menu !== subMenu) {
                                menu.classList.remove('expanded');
                                menu.previousElementSibling.setAttribute('aria-expanded', 'false');
                                menu.querySelectorAll('a').forEach(link => {
                                    link.tabIndex = -1;
                                });
                            }
                        });
                    } else {
                        subMenu.classList.remove('expanded');
                        item.setAttribute('aria-expanded', 'false');
                        subMenu.querySelectorAll('a').forEach(link => {
                            link.tabIndex = -1;
                        });
                    }
                });
            });
            this.loadTheme();
            this.watchScroll(); 

            window.addEventListener('scroll', this.watchScroll)
        }
    },
    handleFocusOut: function (event) {
        if (!this.el.contains(event.target) && this.el.classList.contains('navOpened')) {
            this.el.classList.remove('navOpened');
            document.body.style.overflowY = 'visible';
            this.toggleMenuButton.setAttribute('aria-expanded', false);
            this.setMenuItemsTabIndex(-1);
            document.removeEventListener('focusin', this.handleFocusOut.bind(this));
        }
    },
    toggleTheme: function () {
        const isDarkMode = this.app.classList.toggle('dark-mode');
        localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
        this.updateThemeIcon(isDarkMode);
        this.themeSwitchButton.setAttribute('aria-pressed', isDarkMode);
    },
    setMenuItemsTabIndex: function (index) {
        this.allMenuItems.forEach(item => {
            item.tabIndex = index;
        });
    },
    loadTheme: function () {
        const savedTheme = localStorage.getItem('theme') || 'light';
        if (savedTheme === 'dark') {
            this.app.classList.add('dark-mode');
            this.themeSwitchButton.setAttribute('aria-pressed', true);
        } else {
            this.app.classList.remove('dark-mode');
            this.themeSwitchButton.setAttribute('aria-pressed', false);
        }
        this.updateThemeIcon(savedTheme === 'dark');
    },
    updateThemeIcon: function (isDarkMode) {
        if (!this.themeSwitchButton) return;
        const icon = this.themeSwitchButton.querySelector('i');
        const text = this.themeSwitchButton.querySelector('.visually-hidden');
        if (icon) {
            icon.className = isDarkMode ? 'icon-sun' : 'icon-moon';
        }
        if (text) {
            text.textContent = isDarkMode ? text.getAttribute('data-text-dark') : text.getAttribute('data-text-light');
        }
    }, 
    watchScroll: function (){
        if(window.scrollY !== 0) Header.el.classList.add('scrolled--')
        else Header.el.classList.remove('scrolled--')
    }
};

export default Header;