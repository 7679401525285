'use strict'

// A sample component

const Tabs =  {
    btns: [], 
    slides: [],
    init: function(){
        Tabs.btns = document.querySelectorAll('.tab-btn')
        Tabs.slides = document.querySelectorAll('.tab-slide')

        if(Tabs.btns.length && Tabs.slides.length){
            Tabs.btns.forEach(tab => {

                tab.addEventListener('click', (e) => {

                    /* Reset all */
                    Tabs.btns.forEach( btn => {
                        btn.classList.remove('active');
                        btn.ariaSelected=false
                    })

                    Tabs.slides.forEach( slide => {
                        slide.classList.remove('active')
                    }) 

                    /* Display current */
                    e.target.classList.add('active');
                    e.target.ariaSelected = true;

                    let id = e.target.dataset.value; 

                    let currentSlide = document.querySelector(`.tab-slide[data-id="${id}"]`);
                    currentSlide.classList.add('active')
                })
            })
        }
    },
}


export default Tabs;
