import Header from "./components/Header";
import SliderSingle from "./blocks/slider_single";
import SliderMulti from "./blocks/slider_multi";
import Video from "./blocks/video";
import Accordion from "./blocks/accordion";
import Form from "./components/Form";
import Select from "./components/Select";
import Tabs from "./components/Tabs";
import SliderTimeline from "./blocks/slider_timeline";


const main = {
    init: function() {
        Header.init();
        SliderSingle.init();
        SliderMulti.init();
        SliderTimeline.init();
        Video.init();
        Accordion.init();
        Select.init();
        Form.init();
        Tabs.init();
    }
};

export default main